@primary: #fbbe3f;
@gray: #828282;
@light-gray: #f8f8f8;

.gridHoverStyle {
    background-color: #fff3a1 !important;
}
.gridSelection {
    background-color: #fff3a1 !important;
}
.timelineSelection {
    background-color: #fff3a1 !important;
}
.gantt_grid_scale {
    .gantt_grid_head_cell {
        color: #a6a6a6;
        // border-top: none !important;
        // border-right: none !important;
        border-bottom: solid 1px @gray;
        border-right: solid 1px @gray;
    }
    color: #6b6b6b;
    font-size: 12px;
    box-sizing: border-box;
    background-color: #fff;
    .gantt_grid_column_resize_wrap {
        cursor: col-resize;
        position: absolute;
        width: 13px;
        margin-left: -7px;
    }
    .gantt_last_cell {
        border-right-width: 0;
    }
}
.gantt_grid_data {
    .gantt_row_project {
        background-color: @light-gray;
    }

    .gantt_cell {
        border-right: none;
    }
    width: 100%;
    overflow: hidden;
    position: relative;
    .gantt_row.odd {
        &:hover {
            background-color: #fff3a1 !important;
            .gantt_add {
                -moz-opacity: 1;
                opacity: 1;
            }
        }
    }
    .gantt_row {
        &:hover {
            background-color: #fff3a1 !important;
            .gantt_add {
                -moz-opacity: 1;
                opacity: 1;
            }
        }
    }
    .gantt_last_cell {
        border-right-width: 0;
    }
    .gantt_row.gantt_selected {
        background-color: #fff3a1 !important;
    }
    .gantt_row.odd.gantt_selected {
        background-color: #fff3a1 !important;
    }
}
.gantt_task_link {
    .gantt_link_arrow_right {
        border-width: 6px;
        margin-top: -3px;
    }
    .gantt_link_arrow_left {
        border-width: 6px;
        margin-left: -6px;
        margin-top: -3px;
    }
    .gantt_link_arrow_down {
        border-width: 6px;
    }
    .gantt_link_arrow_up {
        border-width: 6px;
    }
    .gantt_line_wrapper {
        position: absolute;
        cursor: pointer;
    }
    &:hover {
        .gantt_line_wrapper {
            div {
                box-shadow: 0 0 5px 0 @gray;
            }
        }
    }
    div.gantt_link_arrow {
        background-color: transparent;
        border-style: solid;
        width: 0;
        height: 0;
    }
}
.gantt_task_line {
    .gantt_task_progress_drag {
        bottom: -4px;
        height: 10px;
        margin-left: -8px;
        width: 16px;
        z-index: 7;
    }
    border-radius: 2px;
    position: absolute;
    box-sizing: border-box;
    background-color: @gray;
    -webkit-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none;
    &:hover {
        .gantt_link_control {
            div {
                display: block;
            }
        }
        .gantt_task_drag {
            display: block;
        }
        .gantt_task_progress_drag {
            display: block;
        }
    }
}
.gantt_dependent_task, .gantt_project {
    background-color: @primary;
    border-color: #fff3a1;
}
.chartHeaderBg {
    background-color: #fff;
}
.gantt_task {
    .gantt_task_scale {
        .gantt_scale_cell {
            color: #2a2f35;

            &.gantt_scale_cell_week {
                color: #2a2f35;
            }

            &.day_scale {
                background-color: #f8f8f8;
                border-left: 1px solid @gray;
                border-bottom: 1px solid @gray;
            }

            &.current_day {
                background-color: @primary;
            }

            &.gantt_last_cell {
                &.day_scale {
                    border-right: 1px solid @gray;
                }
            }
        }
    }
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    display: inline-block;
    vertical-align: top;
}
.gantt_row.gantt_row_project {
    font-weight: bold;
}
.gantt_row.gantt_project {
    background-color: #edffef;
}
.gantt_row.odd.gantt_project {
    background-color: #edffef;
}
.gantt_task_row.gantt_project {
    background-color: #f5fff6;
}
.gantt_task_row.odd.gantt_project {
    background-color: #f5fff6;
}
.gantt_task_line.gantt_bar_folder,
.gantt_task_line.gantt_bar_planning {
    background: none;
    border: none;
}
.gantt_task_line.gantt_bar_job {
    background-color: #828282;
}
.gantt_task_line.gantt_bar_planning {
    .gantt_task_content > div > div {
        background-color: #2a2f35;
    }
}
.buttonBg {
    background: #fff;
}
.gantt_cal_light {
    .gantt_btn_set {
        margin: 5px 10px;
    }
    -webkit-tap-highlight-color: transparent;
    background: #fff;
    border-radius: 6px;

    font-size: 13px;
    border: 1px solid #cecece;
    color: #6b6b6b;
    font-size: 12px;
    position: absolute;
    z-index: 10001;
    width: 550px;
    height: 250px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    select {
        border: 1px solid #cecece;
        font-size: 13px;
        padding: 2px;
        margin: 0;
    }
    .gantt_title {
        padding-left: 10px;
    }
    input {
        font-size: 13px;
    }
}
.gantt_btn_set.gantt_cancel_btn_set {
    background: #fff;
    color: #454545;
    border: 1px solid #cecece;
}
.gantt_btn_set.gantt_save_btn_set {
    background: @gray;
    text-shadow: 0 -1px 0 #248a9f;
    color: #fff;
}
.gantt_btn_set.gantt_delete_btn_set {
    text-shadow: 0 -1px 0 #6f6f6f;
    background: #ec8e00;
    text-shadow: 0 -1px 0 #a60;
    color: #fff;
}
.gantt_cal_light_wide {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 650px;
    width: 580px;
    padding: 2px 4px;
    .gantt_cal_larea {
        border-left: none !important;
        border-right: none !important;
        box-sizing: border-box;
        border: 1px solid #cecece;
        padding: 0 10px;
        width: 100%;
    }
    .gantt_cal_lsection {
        border: 0;
        float: left;
        text-align: right;
        width: 80px;
        height: 20px;
        padding: 5px 10px 0 0;
        .gantt_fullday {
            float: none;
            margin-right: 0;
            font-weight: 700;
            cursor: pointer;
        }
    }
    .gantt_wrap_section {
        position: relative;
        padding: 10px 0;
        overflow: hidden;
        border-bottom: 1px solid #ebebeb;
    }
    .gantt_section_time {
        overflow: hidden;
        padding-top: 2px !important;
        padding-right: 0;
        height: 20px !important;
        background: transparent;
    }
    .gantt_cal_ltext {
        padding-right: 0;
    }
    .gantt_cal_checkbox {
        label {
            padding-left: 0;
        }
    }
    .gantt_custom_button {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 2px;
    }
    .gantt_repeat_right {
        margin-right: 55px;
    }
}
.gantt_popup_button.gantt_ok_button {
    background: @gray;
    text-shadow: 0 -1px 0 #248a9f;
    color: #fff;
    font-weight: 700;
    border-width: 0;
}
.gantt_popup_button.gantt_cancel_button {
    font-weight: 700;
    color: #454544;
}
.gantt_popup_title {
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-width: 0;
    border-bottom: 1px solid #cecece;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
}
.gantt_popup_shadow {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
}
.gantt_qi_big_icon.icon_edit {
    color: #454545;
    background: #fff;
}
.gantt_qi_big_icon.icon_delete {
    text-shadow: 0 -1px 0 #a60;
    background: #ec8e00;
    color: #fff;
    border-width: 0;
}
.gantt_tooltip {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    border-left: 1px solid rgba(0, 0, 0, 0.07);
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    font-size: 8pt;
    color: #454545;
    background-color: #fff;

    padding: 10px;
    position: absolute;
    z-index: 100;
    white-space: nowrap;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.gantt_container {
    background-color: #fff;
    font-size: 13px;
    position: relative;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.gantt_touch_active {
    overscroll-behavior: none;
}
.gantt_task_scroll {
    overflow-x: scroll;
}
.gantt_grid {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    display: inline-block;
    vertical-align: top;
    div {
        -ms-touch-action: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}
.gantt_task_scale {
    color: #6b6b6b;
    font-size: 12px;
    box-sizing: border-box;
    background-color: #fff;
}
.gantt_task_vscroll {
    background-color: #fff;
}
.gantt_scale_line {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    clear: both;
    border-bottom: 1px solid @light-gray;
    &:first-child {
        border-top: none;
    }
}
.gantt_grid_head_cell {
    display: inline-block;
    vertical-align: top;
    border-right: 1px solid #cecece;
    text-align: center;
    position: relative;
    cursor: default;
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    line-height: 33px;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    line-height: inherit;
    .gantt_sort {
        position: absolute;
        right: 5px;
        top: 8px;
        width: 7px;
        height: 13px;
        background-repeat: no-repeat;
        background-position: 50%;
    }
    .gantt_sort.gantt_asc {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAANCAYAAABlyXS1AAAARUlEQVR4nGNgQAKGxib/GbABkIS7b8B/DAUwCRiGK0CXwFBAb1DfP/U/LszwHwi2X7qFgUEArBtdAVwCBmAKMCSQFSDzAWXXaOHsXeqkAAAAAElFTkSuQmCC);
    }
    .gantt_sort.gantt_desc {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAANCAYAAABlyXS1AAAARUlEQVR42mNgQAL1/VP/M2ADIIntF2/9x1AAlrh0C47hCmA60DFYwX88gIFGwNDY5D8uDFbg7hvwHx2jmIBTAlkB0e4BAEjlaNtBWJPnAAAAAElFTkSuQmCC);
    }
    &:focus {
        box-shadow: inset 0 0 1px 1px #4d90fe;
    }
}
.gantt_row {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none;
    border-bottom: 1px solid #ebebeb;
    background-color: #fff;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    > div {
        flex-shrink: 0;
        flex-grow: 0;
    }
    &:focus {
        box-shadow: inset 0 0 1px 1px #4d90fe;
    }
    &.gantt_row_root_project {
        color: gray;
        font-weight: bold;
    }
    &.gantt_row_folder {
        color: @primary;
        font-weight: bold;
    }
    &.gantt_row_planning {
        color: #2a2f35;
    }
    &.gantt_row_job {
        color: #828282;
    }
}
.gantt_add,
.gantt_add_clone {
    width: 100%;
    height: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTQ3MjMyMENDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTQ3MjMyMERDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NDcyMzIwQUM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NDcyMzIwQkM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PshZT8UAAABbSURBVHjaYrTdeZmBEsCER+4wEP+H4sPkGGCDg020ARR7gb4GIAcYDKMDdPnDyAbYkGG5DVW9cIQMvUdBBAuUY4vDz8iAcZinA2zgCHqAYQMseAywJcYFAAEGAM+UFGuohFczAAAAAElFTkSuQmCC);
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    // -moz-opacity: .3;
    // opacity: .3;
}
.gantt_task_action {
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    -moz-opacity: 1;
    transition: transform .2s;
    opacity: 1;
    &:hover {
        transform: scale(1.2);
    }
}

.gantt_add_clone {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTQ3MjMyMENDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTQ3MjMyMERDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NDcyMzIwQUM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NDcyMzIwQkM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PshZT8UAAABbSURBVHjaYrTdeZmBEsCER+4wEP+H4sPkGGCDg020ARR7gb4GIAcYDKMDdPnDyAbYkGG5DVW9cIQMvUdBBAuUY4vDz8iAcZinA2zgCHqAYQMseAywJcYFAAEGAM+UFGuohFczAAAAAElFTkSuQmCC);
}
.gantt_add_inside {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAIVBMVEUAAAAzzMxCs9A/udQ7udU8utM+udI9udM+udI9udP////XNb6OAAAACXRSTlMAChtNW5Cur+JyRRqJAAAAAWJLR0QKaND0VgAAAE1JREFUCNdjmDlzMgOD5cyZDFNdnBgYVFwiGaYwgIEniMEoAGWwF4AYRgwMbAkMDMogeRADCBjLMtrSBZAZMCm4Yrh2ZAMhVsAthTkDAE+eFlGdYLjKAAAAAElFTkSuQmCC);
}
.gantt_edit {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAI8SURBVDhPjZJdSFNRHMD/55x7t7vZkkD62GrIRFprfZhkEyKkJfgQ9GBuNjFC5t76oKfKQAKpHnqIwhdzIyrdygwiCieCQcyXQqqHoh6C8qVSct55d3fv2jndHYdUTOv3+Dv/3/m4XAT/QX3klfhGfj1KGStQWhmEkYBe9P+O2x4QQZTjdc51bYwxmP48/5gWKgPFDVaPe3ux8NEZtUnkxOQFPzg3VsGRaxOQ+vCVb0BKY2Uh9s6bNqsUAUQgq1M46nNBoNEFT6dn3HOZhaoVY9I+eMVqMZ9t8e0C345aGE29h0/f0iCJAvSPv8thhi+WvbZwLNpjNol9LY07ocaxAUxGoKga3Hn2AtIZJU8E3Krf63qCS/PLCKHoKVEgfc37vOCyr+dhkUxWhWwuV8CYdRbDovsjFjsGuwhG1w81ePmJxuncz6YzEE9OMS1fiOSHu+9zabAci6FYEAEe8O/1ototm8BiNnE/LyswnEyBqutnaCIc47IEj0koehgYu9tU7yFbnXawSkuhrKgwZIRKTuuhie4bXP4GJu2xg4iykQN7tomeagdUWMx8YdH4QENjKVhQ1Ks0Hr7M5V9gyui53e5qabtrM6yxSlyqmm68MQVpebGfJcLnuSwDBkaxzWqBtRUWLrR8HuLjUzCXlm8XEuGTXK4AQoGBif11bn+DpwaMvx4eTb6Eme8/kkzQj0NO/FmaMyaN63hms/C8CYPlLYGx0xpCwVsPjaXWpYlV+cIougSYORCDZob0jl95Kdfwhdc6owAAAABJRU5ErkJggg==);
}
.gantt_delete {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAuElEQVRIie2VTQrCMBSEv3oBb+DClYv0Hnp2G2/gQiuI7SHGTQIhpGiaarvowIP8zjzm5QdWlEKwE1wFiuIm2H/aXw2QakwyVYJvM4ZoUUhaBPk2peyBP1i0GAEL9EG/By5FysF5Pwu2glrQuTBurPHrSgReAuPGTNTuphCQI6qDuYPgGa4Z4pnvokXZ/9SixhXUE05eZAlsmK1r229qMOtT8cjgb3OSAUBwErSJjyaOu+CYLbDC4w1r16F7/9auaQAAAABJRU5ErkJggg==);
}
.gantt_split_task {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pg0KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgPjxnIGlkPSJsYXllcjEiPjxwYXRoIGZpbGw9IiMzMzdEQjgiIHN0cm9rZT0iIzMzN0RCOCIgZD0iTSAwIDIgTCAwIDE4IEwgMjAgMTggTCAyMCAxNyBMIDEgMTcgTCAxIDIgTCAwIDIgeiBNIDMgNCBMIDMgNSBMIDE4IDUgTCAxOCA0IEwgMyA0IHogTSAzIDYgTCAzIDcgTCA5IDcgTCA5IDYgTCAzIDYgeiBNIDkgNyBMIDkgOCBMIDEyIDggTCAxMiA3IEwgOSA3IHogTSAxMiA4IEwgMTIgOSBMIDE4IDkgTCAxOCA4IEwgMTIgOCB6IE0gNiAxMSBMIDYgMTIgTCAxNSAxMiBMIDE1IDExIEwgNiAxMSB6IE0gNiAxMyBMIDYgMTQgTCAxMCAxNCBMIDEwIDEzIEwgNiAxMyB6IE0gMTAgMTQgTCAxMCAxNSBMIDE1IDE1IEwgMTUgMTQgTCAxMCAxNCB6ICIgc3R5bGU9ImZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lO3N0cm9rZS13aWR0aDowcHg7Ii8+PC9nPjwvc3ZnPg0K);
}
.gantt_unsplit_task {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pg0KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgPjxnIGlkPSJsYXllcjEiPjxwYXRoIGZpbGw9IiNGQkJFM0YiIHN0cm9rZT0iI0ZCQkUzRiIgZD0iTSAwIDIgTCAwIDE4IEwgMjAgMTggTCAyMCAxNyBMIDEgMTcgTCAxIDIgTCAwIDIgeiBNIDMgNCBMIDMgNSBMIDE4IDUgTCAxOCA0IEwgMyA0IHogTSAzIDYgTCAzIDcgTCA5IDcgTCA5IDYgTCAzIDYgeiBNIDkgNyBMIDkgOCBMIDEyIDggTCAxMiA3IEwgOSA3IHogTSAxMiA4IEwgMTIgOSBMIDE4IDkgTCAxOCA4IEwgMTIgOCB6IE0gNiAxMSBMIDYgMTIgTCAxNSAxMiBMIDE1IDExIEwgNiAxMSB6IE0gNiAxMyBMIDYgMTQgTCAxMCAxNCBMIDEwIDEzIEwgNiAxMyB6IE0gMTAgMTQgTCAxMCAxNSBMIDE1IDE1IEwgMTUgMTQgTCAxMCAxNCB6ICIgc3R5bGU9ImZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lO3N0cm9rZS13aWR0aDowcHg7Ii8+PC9nPjwvc3ZnPg0K);
}
.gantt_duplicate {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGZpbGw9IiMzREI5RDMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNNSAzaDEydjRoNHYxNEg3di00SDNWM2gyem0xMCA0VjVINXYxMGgyVjdoOHpNOSAxN3YyaDEwVjlIOXY4eiIgZmlsbD0iIzNEQjlEMyIvPgo8L3N2Zz4K)
}
.gantt_grid_head_add {
    width: 100%;
    height: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTQ3MjMyMENDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTQ3MjMyMERDNkI0MTFFMjk4MTI5QTg3MDhFNDVDQTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NDcyMzIwQUM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NDcyMzIwQkM2QjQxMUUyOTgxMjlBODcwOEU0NUNBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PshZT8UAAABbSURBVHjaYrTdeZmBEsCER+4wEP+H4sPkGGCDg020ARR7gb4GIAcYDKMDdPnDyAbYkGG5DVW9cIQMvUdBBAuUY4vDz8iAcZinA2zgCHqAYQMseAywJcYFAAEGAM+UFGuohFczAAAAAElFTkSuQmCC);
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    -moz-opacity: 0.3;
    opacity: 0.3;
}
.gantt_grid_head_cell.gantt_grid_head_add {
    -moz-opacity: 0.6;
    opacity: 0.6;
    top: 0;
    &:hover {
        -moz-opacity: 1;
        opacity: 1;
    }
}
.gantt_task_row {
    border-bottom: 1px solid #ebebeb;
    background-color: #fff;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.gantt_row.odd {
    background-color: #fff;
}
.gantt_task_row.odd {
    background-color: #fff;
}
.gantt_cell {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    vertical-align: top;
    border-right: 1px solid #ebebeb;
    padding-left: 6px;
    padding-right: 6px;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    &:focus {
        box-shadow: inset 0 0 1px 1px #4d90fe;
    }
}
.gantt_scale_cell {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    line-height: inherit;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    height: 100%;
}
.gantt_task_cell {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: inline-block;
    height: 100%;
    border-left: 1px solid @gray;

    &.gantt_last_cell {
        border-right: 1px solid @gray;
    }

    &.current_day {
        background-color: rgba(@primary, 0.3);
    }

    &.no_working_day {
        background-color: rgba(@primary, 0.5);
        z-index: 2;
    }
}
.gantt_grid_column_resize_wrap {
    .gantt_grid_column_resize {
        background-color: #cecece;
        height: 100%;
        width: 1px;
        margin: 0 auto;
    }
}
.gantt_task_grid_row_resize_wrap {
    cursor: row-resize;
    position: absolute;
    height: 7px;
    margin-top: -7px;
    left: 0;
    width: 100%;
    .gantt_task_grid_row_resize {
        background-color: #ebebeb;
        top: 6px;
        height: 1px;
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
}
.gantt_drag_marker.gantt_grid_resize_area {
    background-color: hsla(0, 0%, 91%, 0.5);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-left: 1px solid #cecece;
    border-right: 1px solid #cecece;
}
.gantt_drag_marker.gantt_row_grid_resize_area {
    background-color: hsla(0, 0%, 91%, 0.5);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    pointer-events: none;
}
.gantt_cell_tree {
    display: flex;
    padding-left: 0px !important;
    flex-wrap: nowrap;
}
.gantt_task_bg {
    overflow: hidden;
}
.gantt_layout_cell.gantt_ver_scroll {
    width: 0;
    background-color: transparent;
    height: 1px;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    z-index: 1;
}
.gantt_ver_scroll {
    > div {
        width: 1px;
        height: 1px;
    }
}
.gantt_hor_scroll {
    height: 0;
    background-color: transparent;
    width: 100%;
    clear: both;
    overflow-x: scroll;
    overflow-y: hidden;
    > div {
        width: 5000px;
        height: 1px;
    }
}
.gantt_layout_cell {
    .gantt_hor_scroll {
        position: absolute;
    }
    position: relative;
    box-sizing: border-box;
    > .gantt_layout_header {
        background: #33aae8;
        color: #fff;
        font-size: 17px;
        padding: 5px 10px;
        box-sizing: border-box;
    }
}
.gantt_tree_icon {
    flex-grow: 0;
    flex-shrink: 0;
    vertical-align: top;
    width: 28px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
}
.gantt_tree_indent {
    flex-grow: 0;
    flex-shrink: 0;
    width: 15px;
    height: 100%;
}
.gantt_tree_content {
    vertical-align: top;
    height: 100%;
    white-space: nowrap;
    min-width: 0;
}
.gantt_tree_icon.gantt_open {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAACXBIWXMAAAuUAAALlAF37bb0AAAAIGNIUk0AAHolAACAgwAA+f8AAIDoAABSCAABFVgAADqXAAAXb9daH5AAAAH/SURBVHgBAO8BEP4B////AAAAAAB1dXWV2NjYa+7u7gAJCQkA////AAAAAAAAAAAAvb29AAAAAAACAAAAAAAAAAAGBgZqKysrvREREQD7+/sACAgIAAgICAAICAgAAAAAAAAAAAACAAAAAAAAAAD6+vr1BAQEQisrK8YQEBAA9vb2AP39/QD9/f0AAAAAAAAAAAACAAAAAAAAAAABAQEA+fn5AAUFBTkqKirWCAgIBPLy8gD5+fkAAAAAAAAAAAACAAAAAAAAAAAAAAAAAQEBAPn5+QADAwMpMjIy3xkZGRsEBAQAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAAEBAQD4+PgABQUFHCQkJKoVFRUwAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAP///wAICAgA+/v76N3d3Vvx8fHgAAAAAAAAAAAEAAAAAAAAAAAAAAAA////AAcHBwD9/f3b0dHRLfDw8PkGBgYAAAAAAAAAAAAEAAAAAAAAAAD///8ABwcHAPv7+8vY2Ng29/f3AA4ODgAAAAAAAAAAAAAAAAACAAAAAAAAAAAGBgYL+/v7w9LS0jbu7u4ACAgIAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAD4+Pia2traPvj4+AAMDAwA////AAAAAAAAAAAAAAAAAAAAAAABAAD//z9HdQMRW/QpAAAAAElFTkSuQmCC);
    width: 18px;
    cursor: pointer;
}
.gantt_tree_icon.gantt_close {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAACXBIWXMAAAuUAAALlAF37bb0AAAAIGNIUk0AAHolAACAgwAA+f8AAIDoAABSCAABFVgAADqXAAAXb9daH5AAAAH/SURBVHgBAO8BEP4B////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABcnJymQgICGb6+vr1AQEBAAAAAAAAAAAAAAAAAAAAAAD///8ABgYGC/r6+pYDFxcXtBEREUMGBgYk/Pz8BgEBAQYAAAAGAAAABv///wYHBwcG/f39vtfX11cC8PDwANLS0j77+/vLBwcHAP///wAAAAAA////AAcHBwD7+/vH1NTUQ+7u7gACCAgIAPT09ADY2Ng2/f392wcHBwD+/v4ABwcHAP39/dfY2Ng68/PzAAkJCQAC////AAwMDADy8vIA0dHRLf///+gMDAwA////5NDQ0C7y8vIADAwMAP///wACAAAAAP///wAHBwcA8PDw+dnZ2Tn39/fG2NjYOPHx8fwHBwcAAAAAAAAAAAADJCQkAAAAAAAAAAAA////APv7+/D8/PzO7+/v2wICAgAEBAQAAgICAPz8/AAB////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAD//4RfjvnDJoM6AAAAAElFTkSuQmCC);
    width: 18px;
    cursor: pointer;
}
.gantt_tree_icon.gantt_blank {
    width: 18px;
}
.gantt_tree_icon.gantt_folder_open {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAs0lEQVQ4T62T0Q2EIBBEpQlzuaaMsQoqooqLsSljbMLLmMxmUXBR4U+Qt7Mzi2sqLVeJ00SgEMKWAnvvzYLyAyHfT5sU2fXDJSwCAXK8MI0/UTkva7IIFJsg3NSwnKdFoKtAWOQ1CN7CEqeTotE5L7QyJhmBcklZM4ZgTiAr3iOU3kD93ppO5SkMjB1EeXdBWoSkRql3YeIRe+cGvktS056JR9wsmeBUkujCfNXWCPC8GugPqn5ii/hV+FoAAAAASUVORK5CYII=);
}
.gantt_tree_icon.gantt_folder_closed {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAfElEQVQ4T2NkoBJgpJI5DCgGTZ8+/T82gzMzMwlaCFcAM0RKQgyrI/0Dg/EahmIQyBB0DRvXr4W78tmLV1gtAbmYoEEgnciG4QpTogzCFyEwSyg2CBS2oCAZNQh3cA+hMAJ5AlcKxuVBlOgnNgVjMwyUrQjmamKLGaoZBAAOTFyLnFFW4wAAAABJRU5ErkJggg==);
}
.gantt_tree_icon.gantt_file {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAeElEQVQ4T2NkoBJgRDZn+vTp/wmZm5mZiaIHph7DICkJMUJmMfgHBmMYhtUgbAo3rl+L4lp0NUQbBPI2umuRDaPIIFAYwAyjv0HoMQALM5JdhG4QLMxGDcKdyIdoGIE89OzFK4KZF5Rl8EY/QROQFGA1iBQD0NUCAJVjcxO0naAQAAAAAElFTkSuQmCC);
}
.gantt_inserted {
    font-weight: 700;
}
.gantt_updated {
    font-weight: 700;
}
.gantt_deleted {
    text-decoration: line-through;
}
.gantt_invalid {
    background-color: #ffe0e0;
}
.gantt_error {
    color: red;
}
.gantt_status {
    right: 1px;
    padding: 5px 10px;
    background: hsla(0, 0%, 61%, 0.1);
    position: absolute;
    top: 1px;
    transition: opacity 0.2s;
    opacity: 0;
}
.gantt_status.gantt_status_visible {
    opacity: 1;
}
#gantt_ajax_dots {
    span {
        transition: opacity 0.2s;
        background-repeat: no-repeat;
        opacity: 0;
    }
    span.gantt_dot_visible {
        opacity: 1;
    }
}
.gantt_column_drag_marker {
    border: 1px solid #cecece;
    opacity: 0.8;
}
.gantt_grid_head_cell_dragged {
    border: 1px solid #cecece;
    opacity: 0.3;
}
.gantt_grid_target_marker {
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: @gray;
    transform: translateX(-1px);
    &:after {
        display: block;
        content: '';
        position: absolute;
        left: -5px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        bottom: 0;
        border-bottom-color: @gray;
    }
    &:before {
        display: block;
        content: '';
        position: absolute;
        left: -5px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-top-color: @gray;
    }
}
.gantt_message_area {
    position: fixed;
    right: 5px;
    width: 250px;
    z-index: 1000;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.gantt-info {
    min-width: 120px;
    padding: 4px 4px 4px 20px;

    z-index: 10000;
    margin: 5px;
    margin-bottom: 10px;
    transition: all 0.5s ease;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    cursor: pointer;
    font-size: 14px;
    color: #000;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    padding: 0;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #fff;
    img {
        float: left;
        margin-right: 20px;
    }
    div {
        padding: 5px 10px;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #cecece;
    }
}
.gantt-info.hidden {
    height: 0;
    padding: 0;
    border-width: 0;
    margin: 0;
    overflow: hidden;
}
.gantt_modal_box {
    overflow: hidden;
    display: inline-block;
    min-width: 250px;
    // width: 250px;
    text-align: center;
    position: fixed;
    z-index: 20000;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);

    border-radius: 6px;
    border: 1px solid #cecece;
    background: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    img {
        float: left;
        margin-right: 20px;
    }
}
.gantt_button {
    border: 1px solid #cecece;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin: 0 5px;
    border-radius: 4px;
    background: #fff;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    cursor: pointer;
    input {
        border-radius: 4px;
        font-size: 14px;
        box-sizing: content-box;
        padding: 0;
        margin: 0;
        vertical-align: top;
    }
}
.gantt_popup_button {
    border: 1px solid #cecece;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin: 0 5px;
    border-radius: 4px;
    background: #fff;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    cursor: pointer;
    min-width: 100px;
    div {
        border-radius: 4px;
        font-size: 14px;
        box-sizing: content-box;
        padding: 0;
        margin: 0;
        vertical-align: top;
    }
    &:focus {
        box-shadow: inset 0 0 1px 1px #4d90fe;
    }
}
.gantt_popup_text {
    overflow: hidden;
    margin: 15px 15px 5px;
    font-size: 14px;
    color: #000;
    min-height: 30px;
    border-radius: 6px;
}
.gantt_popup_controls {
    border-radius: 6px;
    padding: 10px;
}
div.dhx_modal_cover {
    background-color: #000;
    cursor: default;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=20);
    opacity: 0.1;
    position: fixed;
    z-index: 19999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    zoom: 1;
}
.gantt-alert-error {
    border: 1px solid red;
}
.gantt-confirm-error {
    border: 1px solid red;
}
.gantt-error {
    font-size: 14px;
    color: #000;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    padding: 0;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #fff;
    background-color: #d81b1b;
    border: 1px solid #ff3c3c;
    div {
        background-color: #d81b1b;
        border: 1px solid #940000;
        color: #fff;
    }
}
.gantt-warning {
    background-color: #ff9000;
    border: 1px solid #ffa633;
    div {
        background-color: #ff9000;
        border: 1px solid #b36500;
        color: #fff;
    }
}
.gantt_data_area {
    div {
        -ms-touch-action: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
}
.gantt_links_area {
    position: absolute;
    left: 0;
    top: 0;
}
.gantt_side_content {
    line-height: inherit;
    overflow: hidden;
    height: 100%;
    position: absolute;
    white-space: nowrap;
    color: #6e6e6e;
    top: 0;
    font-size: 11px;
    z-index: 5;
    overflow: visible;
}
.gantt_task_content {
    line-height: inherit;
    overflow: hidden;
    height: 100%;
    font-size: 12px;
    color: #fff;
    width: 100%;
    top: 0;
    cursor: pointer;
    position: absolute;
    white-space: nowrap;
    text-align: left;
    // z-index: 3;
}
.gantt_task_progress {
    line-height: inherit;
    overflow: hidden;
    height: 100%;
    text-align: center;
    z-index: 0;
    background: black;
    opacity: 0.15;
}
.gantt_task_progress_wrapper {
    border-radius: inherit;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}
.gantt_task_line.gantt_drag_move {
    div {
        cursor: move;
    }
    .gantt_link_control {
        div {
            display: block;
        }
    }
    .gantt_task_drag {
        display: block;
    }
    .gantt_task_progress_drag {
        display: block;
    }
}
.gantt_touch_move {
    transform: scale(1.02, 1.1);
    transform-origin: 50%;
}
.gantt_touch_progress {
    .gantt_touch_resize {
        transform: scale(1.02, 1.1);
        transform-origin: 50%;
    }
    .gantt_task_progress_drag {
        transform: scaleY(1.3);
        transform-origin: 50%;
    }
}
.gantt_touch_resize {
    .gantt_task_drag {
        transform: scaleY(1.3);
        transform-origin: 50%;
    }
}
.gantt_side_content.gantt_left {
    right: 100%;
    padding-right: 20px;
}
.gantt_side_content.gantt_right {
    left: 100%;
    padding-left: 20px;
}
.gantt_side_content.gantt_link_crossing {
    bottom: 8.75px;
    // top: auto;
}
// add background
.gantt_side_content.gantt_link_crossing .gantt_side_text {
    background: rgba(255, 255, 255, 0.8);
}
.gantt_link_arrow {
    position: absolute;
    cursor: pointer;
}
.gantt_line_wrapper {
    div {
        background-color: @gray;
    }
}
.gantt_link_control {
    position: absolute;
    width: 20px;
    top: 0;
    z-index: 7;
    div {
        display: none;
        cursor: pointer;
        box-sizing: border-box;
        position: relative;
        top: 50%;
        margin-top: -7.5px;
        vertical-align: middle;
        border: 1px solid #929292;
        border-radius: 6.5px;
        height: 13px;
        width: 13px;
        background-color: #f0f0f0;
        &:hover {
            background-color: #fff;
        }
    }
}
.gantt_link_control.task_right {
    div.gantt_link_point {
        margin-left: 7px;
    }
    right: -20px;
}
.gantt_link_control.task_left {
    left: -20px;
}
.gantt_link_target {
    .gantt_link_control {
        div {
            display: block;
        }
    }
    box-shadow: 0 0 3px @gray;
}
.gantt_task_line.gantt_drag_progress {
    .gantt_link_control {
        div {
            display: block;
        }
    }
    .gantt_task_drag {
        display: block;
    }
    .gantt_task_progress_drag {
        display: block;
    }
}
.gantt_task_line.gantt_drag_resize {
    .gantt_link_control {
        div {
            display: block;
        }
    }
    .gantt_task_drag {
        display: block;
    }
    .gantt_task_progress_drag {
        display: block;
    }
}
.gantt_task_line.gantt_selected {
    .gantt_link_control {
        div {
            display: block;
        }
    }
    .gantt_task_drag {
        display: block;
    }
    .gantt_task_progress_drag {
        display: block;
    }
    box-shadow: 0 0 5px darken(@gray, 10);
}
.gantt_link_source {
    box-shadow: 0 0 3px @gray;
}
.gantt_link_target.link_finish_allow {
    box-shadow: 0 0 3px #ffbf5e;
}
.gantt_link_target.link_start_allow {
    box-shadow: 0 0 3px #ffbf5e;
}
.gantt_link_target.link_finish_deny {
    box-shadow: 0 0 3px #e87e7b;
}
.gantt_link_target.link_start_deny {
    box-shadow: 0 0 3px #e87e7b;
}
.link_finish_allow {
    .gantt_link_control.task_end_date {
        div {
            background-color: #ffbf5e;
            border-color: @gray;
        }
    }
}
.link_start_allow {
    .gantt_link_control.task_start_date {
        div {
            background-color: #ffbf5e;
            border-color: @gray;
        }
    }
}
.link_finish_deny {
    .gantt_link_control.task_end_date {
        div {
            background-color: #e87e7b;
            border-color: #dd3e3a;
        }
    }
}
.link_start_deny {
    .gantt_link_control.task_start_date {
        div {
            background-color: #e87e7b;
            border-color: #dd3e3a;
        }
    }
}
.gantt_link_arrow_right {
    border-width: 4px 0 4px 6px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: @gray;
}
.gantt_link_arrow_left {
    border-width: 4px 6px 4px 0;
    margin-top: -1px;
    border-top-color: transparent !important;
    border-right-color: @gray;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
}
.gantt_link_arrow_up {
    border-width: 0 4px 6px;
    border-color: transparent transparent @gray;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: @gray;
    border-left-color: transparent !important;
}
.gantt_link_arrow_down {
    border-width: 4px 6px 0 4px;
    border-top-color: @gray;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
}
.gantt_task_drag {
    cursor: ew-resize;
    display: none;
    position: absolute;
    height: 100%;
    width: 10px;
    z-index: 7;
    top: -1px;
}
.gantt_task_progress_drag {
    cursor: ew-resize;
    display: none;
    position: absolute;
    height: 8px;
    width: 8px;
    bottom: -4px;
    margin-left: -4px;
    background-position: bottom;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MkY3Rjk0RUVDMkYzMTFFMkI1OThEQTA3ODU0OTkzMEEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkY3Rjk0RUZDMkYzMTFFMkI1OThEQTA3ODU0OTkzMEEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyRjdGOTRFQ0MyRjMxMUUyQjU5OERBMDc4NTQ5OTMwQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyRjdGOTRFREMyRjMxMUUyQjU5OERBMDc4NTQ5OTMwQSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PobPBzIAAADkSURBVHjaYpk2bRoDDsAExL1QdjEQ/8OmiAWHZk4gXqymqhQM4ty6fU8OSMUA8XdiDBAB4k0a6iqWRga6EKcwMQXduHlnL5DpB8Rv0J2JDFSA+JiOtgZcMwiA2CAxkBxUDVYDLEAKgIpV9XQ0MZwFEgPJAZnHoWpRDAgC4n2W5saiQKfjClQGkBxQDciL+6B6wAbkA/EqJwdrTkUFOQZCAKQGpBbIXA3SCzJggo+XK7OEuBgDsQCkFqgHrBfsBT5eHgZSAUwP2IBfv36TbABMDygdtK1Zv6UESLORaAbIhG6AAAMAKN8wE24DXWcAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    z-index: 2;
    &:hover {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAs0lEQVQoz6WMPW7CQBgFJxZaiZ60qcgdwjVMmzu8gpwhDULabXyBdHAGuzRHivQiQZovigS2+Jtu95t5T03TMITtCtjEc5VSOgx5k5F4CnxJWgKUUl5sv6eUvk/daiCeAe1fDCCpBtq4jQ/YngO9pMWpGH99OOcDtt8ifmWEuO3D/R+wXQOdpGcuIGkGdNFQ2RawlTTlSsLd2RY55+O95JyPFQ/y8MAE+CylfADpxvYHWP8CXj+JR4wdKHYAAAAASUVORK5CYII=);
    }
}
.gantt_task_drag.task_right {
    cursor: e-resize;
    right: -7px;
}
.gantt_task_drag.task_left {
    cursor: w-resize;
    left: -7px;
}
.gantt_link_tooltip {
    box-shadow: 3px 3px 3px #888;
    background-color: #fff;
    border-left: 1px dotted #cecece;
    border-top: 1px dotted #cecece;
    font-size: 8pt;
    color: #444;
    padding: 6px;
    line-height: 20px;
}
.gantt_link_direction {
    height: 0;
    border: 0 none @gray;
    border-bottom-style: dashed;
    border-bottom-width: 2px;
    transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    z-index: 2;
    margin-left: 1px;
    position: absolute;
}
.gantt_task_row.gantt_selected {
    background-color: #fff3a1 !important;
    .gantt_task_cell {
        border-right-color: #ffec6e;
    }
}
.gantt_task_line.gantt_project.gantt_selected {
    box-shadow: 0 0 5px darken(@primary, 10);
}
.gantt_task_line.gantt_milestone {
    visibility: hidden;
    background-color: #d33daf;
    border: 0 solid #61164f;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    div {
        visibility: visible;
    }
    .gantt_task_content {
        background: inherit;
        border: inherit;
        border-width: 1px;
        border-radius: inherit;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        transform: rotate(45deg);
    }
}
.gantt_task_line.gantt_task_inline_color {
    border-color: #999;
    .gantt_task_progress {
        background-color: #363636;
        opacity: 0.2;
    }
}
.gantt_task_line.gantt_task_inline_color.gantt_project.gantt_selected {
    box-shadow: 0 0 5px #999;
}
.gantt_task_line.gantt_task_inline_color.gantt_selected {
    box-shadow: 0 0 5px #999;
}
.gantt_task_link.gantt_link_inline_color {
    &:hover {
        .gantt_line_wrapper {
            div {
                box-shadow: 0 0 5px 0 #999;
            }
        }
    }
}
.gantt_critical_task {
    background-color: #e63030;
    border-color: #9d3a3a;
    .gantt_task_progress {
        background-color: rgba(0, 0, 0, 0.4);
    }
}
.gantt_critical_link {
    .gantt_line_wrapper {
        > div {
            background-color: #e63030;
        }
    }
    .gantt_link_arrow {
        border-color: #e63030;
    }
}
.gantt_btn_set {
    &:focus {
        box-shadow: inset 0 0 1px 1px #4d90fe;
    }
    margin: 10px 7px 5px 10px;
    padding: 5px 15px 5px 10px;
    float: left;
    border-radius: 4px;
    border: 0 solid #cecece;
    height: 32px;
    font-weight: 700;
    background: #fff;
    box-sizing: border-box;
    cursor: pointer;
    div {
        float: left;
        font-size: 13px;
        height: 22px;
        line-height: 22px;
        background-repeat: no-repeat;
        vertical-align: middle;
    }
}
.gantt_qi_big_icon {
    &:focus {
        box-shadow: inset 0 0 1px 1px #4d90fe;
    }
    font-size: 13px;
    border-radius: 4px;
    font-weight: 700;
    background: #fff;
    margin: 5px 9px 8px 0;
    min-width: 60px;
    line-height: 32px;
    vertical-align: middle;
    padding: 0 10px 0 5px;
    cursor: pointer;
    border: 1px solid #cecece;
}
.gantt_split_parent {
    opacity: 0.1;
    pointer-events: none;
}
.gantt_split_subproject {
    opacity: 0.1;
    pointer-events: none;
}
.gantt_rollup_child {
    .gantt_link_control {
        display: none;
    }
    &:hover {
        .gantt_link_control {
            display: none;
        }
    }
}
.gantt_unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none;
    div {
        -webkit-user-select: none;
        -moz-user-select: none;
        -moz-user-select: -moz-none;
    }
}
.gantt_cal_ltitle {
    padding: 7px 10px;
    overflow: hidden;
    -webkit-border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topright: 6px;
    -moz-border-radius-bottomright: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0;
    white-space: nowrap;
    span {
        white-space: nowrap;
    }
}
.gantt_cal_lsection {
    color: #727272;
    font-weight: 700;
    padding: 12px 0 5px 10px;
    font-size: 13px;
    .gantt_fullday {
        float: right;
        margin-right: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        vertical-align: top;
        cursor: pointer;
    }
}
.gantt_cal_ltext {
    padding: 2px 10px;
    overflow: hidden;
    textarea {
        overflow-y: auto;
        overflow-x: hidden;

        font-size: 13px;
        box-sizing: border-box;
        border: 1px solid #cecece;
        height: 100%;
        width: 100%;
        outline: none !important;
        resize: none;
    }
}
.gantt_section_constraint {
    [data-constraint-time-select] {
        margin-left: 20px;
    }
}
.gantt_time {
    font-weight: 700;
}
.gantt_cal_larea {
    border: 1px solid #cecece;
    border-left: none;
    border-right: none;
    background-color: #fff;
    overflow: hidden;
    height: 1px;
}
.gantt_hidden {
    display: none;
}
.gantt_save_btn {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTk1OUU5RDFDMzA0MTFFMkExMUZBQTdDNDAzOUE5RjMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTk1OUU5RDJDMzA0MTFFMkExMUZBQTdDNDAzOUE5RjMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxOTU5RTlDRkMzMDQxMUUyQTExRkFBN0M0MDM5QTlGMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxOTU5RTlEMEMzMDQxMUUyQTExRkFBN0M0MDM5QTlGMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjDroXYAAAEXSURBVHjaYvz//z8DJYCRUgPIAUxAbAnEHiAHMIBcQCwGaRYXF3e6evXqoffv39/dv38/CymaGSUkJBzv3LlzCsj///fv3wdAihkkIQnEvkAshU8zLy+v7a1bt06ANP/79+87kDIAy505cybq06dPr3p7ezuwGQLTfOPGjWP/ESAZLg8kPKBO+g01RBJNszWyZqC6uSgWgIg/f/4shxnS2dnZBjMEqNkSFGBImi8CKTYMA4BYCGjIczRDHC5dunQQSfN7IKWI4UUkjjdMMdCwnw8ePLjwHxV4Yw1gZA5Q47z/2EELzhhCE+ABGvIQWSeQvwcU38QaAML2wHj+C/X3MyAlijeB4ZBoBOIPQGxJKIVSnBsBAgwABddBclWfcZUAAAAASUVORK5CYII=);
    margin-top: 2px;
    width: 21px;
}
.gantt_cancel_btn {
    margin-top: 2px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDkzMDA3MzlDMzA0MTFFMjg2QTVFMzFEQzgwRkJERDYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDkzMDA3M0FDMzA0MTFFMjg2QTVFMzFEQzgwRkJERDYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowOTMwMDczN0MzMDQxMUUyODZBNUUzMURDODBGQkRENiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowOTMwMDczOEMzMDQxMUUyODZBNUUzMURDODBGQkRENiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmYuYOUAAAEdSURBVHjaYvz//z8DJYAFXWDlypU8QKoIiD2A2AwqfAqIdwBxX3h4+Bdk9YzILgBqtgdS84FYEYeF94E4EWjIQZgAE5LmQCB1AKoZZKMPEAtAMYh9GSp3AKjWD8UFQAEhIPshEIOc3wHENUBb/qJ57SyQMoJyPwKxElDNO1gYFEE17wMKVmIJlzNQzeegrjaA6qmBecEbSvfh0GwMxGeBhoPoemQ9MAO0kEIbl2YTqPAFKK2IbMB3AjabYIkRZmQD7kNpMyI0G0PpO8gGbIUFJj7NQDk2INWIrIcJKfBAKcwJqvkcDs0TgFgXGo19KCkRmpDWQdWDEk0NUoCBoq0FqhkE/IEWbKJKUmZEz43QzFSKIzN1481M5ACAAAMAlfl/lCwRpagAAAAASUVORK5CYII=);
    width: 20px;
}
.gantt_delete_btn {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjFENzI3NUNDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjFENzI3NURDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMUQ3Mjc1QUMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyMUQ3Mjc1QkMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmUD0gAAAABvSURBVHjaYvz//z8DIyMjAxYQicReji4J0ofKQNP8HwmgGQbXB8IsWGwDSSwDuioKjY9uBthVjFAXYHUGAQA2kYmBUoAUBpGk0LAwgBvwH+YX4mkwptgLowYMRgOITUyYKRFIN/wnDjQgJySAAAMApryKzL8wjfUAAAAASUVORK5CYII=);
    margin-top: 2px;
    width: 20px;
}
.gantt_cal_cover {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.1;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=10);
}
.gantt_custom_button {
    padding: 0 3px;

    font-size: 13px;
    font-weight: 400;
    margin-right: 10px;
    margin-top: -5px;
    cursor: pointer;
    float: right;
    height: 21px;
    width: 90px;
    border: 1px solid #cecece;
    text-align: center;
    border-radius: 4px;
    div {
        cursor: pointer;
        float: none;
        height: 21px;
        line-height: 21px;
        vertical-align: middle;
        &:first-child {
            display: none;
        }
    }
}
.gantt_cal_light_wide.gantt_cal_light_full {
    width: 738px;
}
.gantt_cal_wide_checkbox {
    input {
        margin-top: 8px;
        margin-left: 14px;
    }
}
.gantt_section_time {
    background-color: #fff;
    white-space: nowrap;
    padding: 2px 10px 5px;
    padding-top: 2px !important;
    .gantt_time_selects {
        float: left;
        height: 25px;
        select {
            height: 23px;
            padding: 2px;
            border: 1px solid #cecece;
        }
    }
}
.gantt_duration {
    width: 100px;
    height: 23px;
    float: left;
    white-space: nowrap;
    margin-left: 20px;
    line-height: 23px;
    .gantt_duration_dec {
        box-sizing: border-box;
        text-align: center;
        vertical-align: top;
        height: 100%;
        border: 1px solid #cecece;
        width: 20px;
        padding: 1px;
        padding-bottom: 1px;
        background: #fff;
        -moz-border-top-left-radius: 4px;
        -moz-border-bottom-left-radius: 4px;
        -webkit-border-top-left-radius: 4px;
        -webkit-border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .gantt_duration_inc {
        box-sizing: border-box;
        text-align: center;
        vertical-align: top;
        height: 100%;
        border: 1px solid #cecece;
        width: 20px;
        padding: 1px;
        padding-bottom: 1px;
        background: #fff;
        margin-right: 4px;
        -moz-border-top-right-radius: 4px;
        -moz-border-bottom-right-radius: 4px;
        -webkit-border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .gantt_duration_value {
        box-sizing: border-box;
        text-align: center;
        vertical-align: top;
        height: 100%;
        border: 1px solid #cecece;
        width: 40px;
        padding: 3px 4px;
        border-left-width: 0;
        border-right-width: 0;
    }
    .gantt_duration_value.gantt_duration_value_formatted {
        width: 70px;
    }
}
.gantt_resources {
    max-height: 150px;
    height: auto;
    overflow-y: auto;
}
.gantt_resource_row {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    input[type='checkbox'] {
        &:not(:checked) {
            opacity: 0.5;
        }
        &:not(:checked)& ~ div {
            opacity: 0.5;
        }
    }
}
.gantt_resource_toggle {
    vertical-align: middle;
}
.gantt_resources_filter {
    .gantt_resources_filter_input {
        padding: 1px 2px;
        box-sizing: border-box;
    }
    .switch_unsetted {
        vertical-align: middle;
    }
}
.gantt_resource_cell {
    display: inline-block;
}
.gantt_resource_cell.gantt_resource_cell_checkbox {
    width: 24px;
    max-width: 24px;
    min-width: 24px;
    vertical-align: middle;
}
.gantt_resource_cell.gantt_resource_cell_label {
    width: 40%;
    max-width: 40%;
    vertical-align: middle;
}
.gantt_resource_cell.gantt_resource_cell_value {
    width: 30%;
    max-width: 30%;
    vertical-align: middle;
    input {
        width: 80%;
        vertical-align: middle;
        padding: 1px 2px;
        box-sizing: border-box;
    }
    select {
        width: 80%;
        vertical-align: middle;
        padding: 1px 2px;
        box-sizing: border-box;
    }
}
.gantt_resource_cell.gantt_resource_cell_unit {
    width: 10%;
    max-width: 10%;
    vertical-align: middle;
}
.gantt_resource_early_value {
    opacity: 0.8;
    font-size: 0.9em;
}
.gantt_cal_quick_info {
    // border: 1px solid #cecece;
    border-radius: 6px;
    position: absolute;
    z-index: 300;
    left: 5px !important;
    bottom: 20px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07);
    background-color: rgba(255, 255, 255, 1);
    width: auto;
    height: 25px;
    transition: left 0.5s ease, right 0.5s;
    -moz-transition: left 0.5s ease, right 0.5s;
    -webkit-transition: left 0.5s ease, right 0.5s;
    -o-transition: left 0.5s ease, right 0.5s;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.gantt_no_animate {
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
}
.gantt_cal_quick_info.gantt_qi_left {
    .gantt_qi_big_icon {
        float: right;
    }
}
.gantt_cal_qi_title {
    -webkit-border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topright: 6px;
    -moz-border-radius-bottomright: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0;
    padding: 5px 0 8px 12px;
    color: #454545;
    background-color: #fff;
    border-bottom: 1px solid #cecece;
}
.gantt_cal_qi_tdate {
    font-size: 14px;
    font-weight: 700;
}
.gantt_cal_qi_tcontent {
    font-size: 13px;
}
.gantt_cal_qi_content {
    // padding: 16px 8px;
    display: flex;
    font-size: 13px;
    color: #454545;
    overflow: hidden;
    justify-content: space-between;
}
.gantt_cal_qi_controls {
    -webkit-border-top-left-radius: 0;
    -webkit-border-bottom-left-radius: 6px;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-topleft: 0;
    -moz-border-radius-bottomleft: 6px;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 6px;
    padding-left: 7px;
    .gantt_menu_icon {
        margin-top: 6px;
        background-repeat: no-repeat;
    }
    .gantt_menu_icon.icon_edit {
        width: 20px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH3QYFCjI5ZQj5bAAAAFNJREFUOMvt0zEOACAIA0DkwTymH8bJTRTKZGJXyaWEKPKTCQAH4Ls37cItcDUzsxHNDLZNhCq7Gt1wh9ErV7EjyGAhyGLphlnsClWuS32rn0czAV+vNGrM/LBtAAAAAElFTkSuQmCC);
    }
    .gantt_menu_icon.icon_delete {
        width: 20px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjFENzI3NUNDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjFENzI3NURDMzA0MTFFMjhBNjJGQTc3MUIyQzYzNEYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMUQ3Mjc1QUMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyMUQ3Mjc1QkMzMDQxMUUyOEE2MkZBNzcxQjJDNjM0RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmUD0gAAAABvSURBVHjaYvz//z8DIyMjAxYQicReji4J0ofKQNP8HwmgGQbXB8IsWGwDSSwDuioKjY9uBthVjFAXYHUGAQA2kYmBUoAUBpGk0LAwgBvwH+YX4mkwptgLowYMRgOITUyYKRFIN/wnDjQgJySAAAMApryKzL8wjfUAAAAASUVORK5CYII=);
    }
    div {
        float: left;
        height: 32px;
        text-align: center;
        line-height: 32px;
    }
}
.gantt_resource_marker {
    position: absolute;
    text-align: center;
    font-size: 14px;
    color: #fff;
}
.gantt_resource_marker_ok {
    background: rgba(78, 208, 134, 0.75);
}
.gantt_resource_marker_overtime {
    background: hsla(0, 100%, 76%, 0.69);
}
.gantt_histogram_label {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    font-weight: 700;
    font-size: 13px;
}
.gantt_histogram_fill {
    background-color: rgba(41, 157, 180, 0.2);
    width: 100%;
    position: absolute;
    bottom: 0;
}
.gantt_histogram_hor_bar {
    height: 1px;
    margin-top: -1px;
    position: absolute;
    background: #299db4;
    margin-left: -1px;
}
.gantt_histogram_vert_bar {
    position: absolute;
    background: #299db4;
    margin-left: -1px;
    width: 1px;
}
.gantt_histogram_cell {
    position: absolute;
    text-align: center;
    font-size: 13px;
    color: #000;
}
.gantt_marker {
    height: 100%;
    width: 2px;
    top: 0;
    position: absolute;
    text-align: center;
    background-color: rgba(255, 0, 0, 0.4);
    box-sizing: border-box;
    .gantt_marker_content {
        padding: 5px;
        background: inherit;
        color: inherit;
        position: absolute;
        font-size: 12px;
        line-height: 12px;
        opacity: 0.8;
    }
}
.gantt_marker_area {
    position: absolute;
    top: 0;
    left: 0;
}
.gantt_grid_editor_placeholder {
    position: absolute;
    > div {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    select {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
}
.gantt_row_placeholder {
    div {
        opacity: 0.5;
    }
    .gantt_add {
        display: none;
    }
    .gantt_file {
        display: none;
    }
}
.gantt_drag_marker.gantt_grid_dnd_marker {
    background-color: transparent;
    transition: all 0.1s ease;
}
.gantt_grid_dnd_marker_line {
    height: 4px;
    width: 100%;
    background-color: #3498db;
    &:before {
        background: #fff;
        width: 12px;
        height: 12px;
        box-sizing: border-box;
        border: 3px solid #3498db;
        border-radius: 6px;
        content: '';
        line-height: 1px;
        display: block;
        position: absolute;
        margin-left: -11px;
        margin-top: -4px;
        pointer-events: none;
    }
}
.gantt_grid_dnd_marker_folder {
    height: 100%;
    width: 100%;
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 2px #3f98db;
    background: transparent;
}
.gantt_overlay_area {
    display: none;
    position: absolute;
    height: inherit;
    width: inherit;
    top: 0;
    left: 0;
}
.gantt_overlay {
    position: absolute;
    height: inherit;
    width: inherit;
    top: 0;
    left: 0;
}
.gantt_click_drag_rect {
    position: absolute;
    left: 0;
    top: 0;
    outline: 1px solid #3f98db;
    background-color: rgba(52, 152, 219, 0.3);
}
.gantt_timeline_move_available {
    cursor: move;
    * {
        cursor: move;
    }
}
.gantt_rtl {
    .gantt_grid {
        text-align: right;
    }
    .gantt_cell {
        flex-direction: row-reverse;
    }
    .gantt_row {
        flex-direction: row-reverse;
    }
}
.gantt_layout_content {
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.gantt_layout_header.collapsed_x {
    background: #a9a9a9;
    .gantt_header_arrow {
        &:before {
            content: '\21E7';
        }
    }
}
.gantt_layout_header.collapsed_y {
    background: #a9a9a9;
    .gantt_header_arrow {
        &:before {
            content: '\21E9';
        }
    }
}
.gantt_layout_header {
    cursor: pointer;
    .gantt_header_arrow {
        float: right;
        text-align: right;
        &:before {
            content: '\21E6';
        }
    }
}
.gantt_layout_header.vertical {
    .gantt_header_arrow {
        &:before {
            content: '\21E7';
        }
    }
}
.gantt_layout_outer_scroll_vertical {
    .gantt_layout_content {
        overflow-y: hidden;
    }
}
.gantt_layout_outer_scroll_horizontal {
    .gantt_layout_content {
        overflow-x: hidden;
    }
}
.gantt_layout_x {
    > .gantt_layout_cell {
        display: inline-block;
        vertical-align: top;
    }
    white-space: nowrap;
}
.gantt_resizing {
    opacity: 0.7;
    background: #f2f2f2;
}
.gantt_layout_cell_border_right.gantt_resizer {
    overflow: visible;
    border-right: 0;
}
.gantt_resizer {
    cursor: e-resize;
    position: relative;
}
.gantt_resizer_y {
    cursor: n-resize;
    .gantt_resizer_y {
        position: absolute;
        height: 20px;
        width: 100%;
        top: -10px;
        left: 0;
        z-index: 1;
    }
}
.gantt_resizer_stick {
    background: #33aae8;
    z-index: 9999;
    position: absolute;
    top: 0;
    width: 100%;
}
.gantt_resizer_x {
    .gantt_resizer_x {
        position: absolute;
        width: 20px;
        height: 100%;
        margin-left: -10px;
        top: 0;
        left: 0;
        z-index: 1;
    }
}
.gantt_resizer_error {
    background: #cd5c5c !important;
}
.gantt_layout_cell_border_transparent {
    border-color: transparent;
}
.gantt_window {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999999999;
    background: #fff;
}
.gantt_window_content {
    position: relative;
}
.gantt_window_content_header {
    background: #39c;
    color: #fff;
    height: 33px;
    padding: 10px 10px 0;
    border-bottom: 2px solid #fff;
    position: relative;
}
.gantt_window_content_header_text {
    padding-left: 10%;
}
.gantt_window_content_header_buttons {
    position: absolute;
    top: 10px;
    right: 10px;
    &:hover {
        color: #000;
        cursor: pointer;
    }
}
.gantt_window_content_resizer {
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 0;
    line-height: 15px;
    right: -1px;
    text-align: center;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAABZJREFUeAFjIAUwUshlpJDLSIhLGAAACQ4AFk79JaMAAAAASUVORK5CYII=);
    cursor: nw-resize;
    z-index: 999;
}
.gantt_window_content_frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 9999;
}
.gantt_window_drag {
    cursor: pointer !important;
}
.gantt_window_resizing {
    overflow: visible;
}
.gantt_window_resizing_body {
    overflow: hidden !important;
}
.gantt_window_modal {
    background: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
}
.gantt_noselect {
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .gantt_grid_data {
        .gantt_row.odd {
            &:hover {
                background-color: unset;
            }
        }
        .gantt_row {
            &:hover {
                background-color: unset;
            }
        }
    }
}
.gantt_drag_marker {
    position: absolute;
    top: -1000px;
    left: -1000px;

    font-size: 13px;
    z-index: 1;
    white-space: nowrap;
    background-color: #fff;
    .gantt_tree_icon.gantt_blank {
        display: none;
    }
    .gantt_tree_icon.gantt_close {
        display: none;
    }
    .gantt_tree_icon.gantt_open {
        display: none;
    }
    .gantt_tree_indent {
        display: none;
    }
    .gantt_row.odd {
        background-color: #fff;
    }
    .gantt_row {
        border-left: 1px solid #d2d2d2;
        border-top: 1px solid #d2d2d2;
    }
    .gantt_cell {
        border-color: #d2d2d2;
    }
}
.gantt_row.gantt_over {
    background-color: #0070fe;
}
.gantt_task_row.gantt_over {
    background-color: #0070fe;
}
.gantt_row.gantt_transparent {
    .gantt_cell {
        opacity: 0.7;
    }
}
.gantt_task_row.gantt_transparent {
    background-color: #f8fdfd;
}
.gantt_popup_button.gantt_delete_button {
    background: @gray;
    text-shadow: 0 -1px 0 #248a9f;
    color: #fff;
    font-weight: 700;
    border-width: 0;
}
.gantt_container_resize_watcher {
    background: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    border: 0;
    box-sizing: border-box;
    opacity: 0;
}

.gantt_project .gantt_link_control {
    display: none !important;
}

.gantt_project .gantt_side_content.gantt_right {
    padding-left: 0 !important;
}

.gantt_project .gantt_side_content.gantt_left {
    padding-right: 0 !important;
}

.freeSlack {
    position: absolute;
    border-radius: 0;
    opacity: 0.7;

    border: none;
    border-right: 1px solid #b6b6b6;
    margin-left: -2px;
    background: #b6b6b6;
    background: repeating-linear-gradient(45deg, #ffffff, #ffffff 5px, #b6b6b6 5px, #b6b6b6 10px);
}
.totalSlack {
    position: absolute;
    border-radius: 0;
    opacity: 0.7;

    border: none;
    border-right: 1px solid #b6b6b6;
    margin-left: -2px;
    background: #b6b6b6;
    background: repeating-linear-gradient(-45deg, #ffffff, #ffffff 5px, #b6b6b6 5px, #b6b6b6 10px);
}
.tasklayer-container {
    position: absolute;
    border-radius: 0;
    // opacity: 0.7;

    // border: none;
    // border-top: 1px solid blue;
    // margin-left: -2px;
    // background: #b6b6b6;
    // background: repeating-linear-gradient(
    //         -45deg, #FFFFFF,
    //         #FFFFFF 5px,
    //         #b6b6b6 5px,
    //         #b6b6b6 10px
    // );
}

.gantt_task_line.gantt_split_parent {
    visibility: hidden;
    .gantt_side_content span {
        visibility: hidden;
    }
    .gantt_task_content span {
        visibility: hidden;
    }
}

.gantt_task_line.gantt_split_subproject.is_split_task {
    display: none;
}

.gantt_task_line.gantt_split_child {
    opacity: 0.5;
    .gantt_side_content span {
        display: none;
    }
    .gantt_task_content span {
        display: none;
    }
    .gantt_link_control {
        display: none;
    }
}

.gantt-excel-form table {
    width: 99%;
    text-align: left;
    border-collapse: collapse;
    // height: 100%;
    // overflow: auto;
}

.gantt-excel-form .dhtmlx_popup_text {
    max-height: 600px;
    max-width: 80vw;
    overflow: auto;
}

.gantt-excel-form table th {
    text-align: center;
}
.gantt-excel-form table td {
    border: 1px solid #aeaeae;
    padding: 5px;
}

.gantt-excel-form table td select {
    width: 100%;
    padding: 5px;
}

.gantt_link_save_btn_button {
    background: rgba(251, 190, 63, 1);
}

.resource_task {
    // height: 10px !important;
    // margin-top: 5px;
    background-color: #EDEDED;
    border: 1px solid #000;
}

.resource-subtask-empty, .resource-task-empty {
    background-color: #fffbdf !important;
}

.ant-badge-count {
    border-radius: unset !important;
}

.react-split {
    flex: 1;
    height: 100%;
    position: relative;
    width: 100%;
  }
  .react-split__pane {
    height: 100%;
    position: absolute;
    white-space: normal;
    width: 100%;
    transition: all 0.2ms linear;
    // overflow: hidden;
  }
  .react-split__sash {
    height: 100%;
    position: absolute;
    top: 0;
    transition: background-color 0.1s;
    width: 100%;
    z-index: 2;
  }
  .react-split__sash--disabled {
    pointer-events: none;
  }
  .react-split__sash--vertical {
    cursor: col-resize;
  }
  .react-split__sash--horizontal {
    cursor: row-resize;
  }
  .react-split__sash-content {
    width: 100%;
    height: 100%;
  }
  .react-split__sash-content--active {
    background-color: rgba(251,190,63,1);
  }
  .react-split--dragging.react-split--vertical {
    cursor: col-resize;
  }
  .react-split--dragging.react-split--horizontal {
    cursor: row-resize;
  }
  
  body.react-split--disabled {
    user-select: none;
  }
  
  .split-sash-content {
    width: 100%;
    height: 100%;
  }
  .split-sash-content.split-sash-content-vscode.split-sash-content-active {
    background-color: rgba(251,190,63,1);
  }

  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td {
    font-family: Lato, sans-serif !important;
    font-size: 12px !important;
  
  }
  .dhtmlx_popup_button:first-child {
    background:@primary !important;
    color:black;
  }

  .dhxmenu_skin_detect {
    position:absolute;
    left:0;
    top:-100px;
    margin:0;
    padding:0;
    border:0 solid white;
    width:30px;
    height:10px;
    overflow:hidden;
  }
  .dhtmlxMenu_dhx_terrace_Middle {
    position:relative;
    height:34px;
    padding:0 5px;
    border:none;
    overflow:hidden;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
  }
  .dhtmlxMenu_dhx_terrace_Middle div.top_sep {
    float:left;
    position:relative;
    height:20px;
    margin:5px 6px 0 5px;
    line-height:1px;
    font-size:1px;
    overflow:hidden;
    cursor:default;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
  }
  .dhtmlxMenu_dhx_terrace_Middle img.dhtmlxMenu_TopLevel_Item_Icon {
    float:left;
    margin:2px 3px 0 3px;
    width:18px;
    height:18px;
  }
  .dhtmlxMenu_dhx_terrace_Middle div.dhtmlxMenu_TopLevel_Text_right {
    position:absolute;
    top:10px;
    left:none;
    right:8px;
    font-family:Arial,Helvetica;
    font-size:13px;
    color:#333;
    cursor:default;
  }
  .dhtmlxMenu_dhx_terrace_Middle div.dhtmlxMenu_TopLevel_Text_left {
    position:absolute;
    top:10px;
    right:none;
    left:8px;
    font-family:Arial,Helvetica;
    font-size:13px;
    color:#333;
    cursor:default;
  }
  .dhtmlxMenu_dhx_terrace_Middle.dir_left div.align_left {
    float:left;
  }
  .dhtmlxMenu_dhx_terrace_Middle.dir_left div.align_right {
    float:right;
  }
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Normal,
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Disabled,
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Selected {
    position:relative;
    float:left;
    font-family:Arial,Helvetica;
    font-size:13px;
    color:#333;
    border:1px solid #ccc;
    background-color:#f5f5f5;
    cursor:default;
    white-space:nowrap;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
    height:22px;
    line-height:22px;
    vertical-align:middle;
    margin-top:2px;
    padding:3px 5px;
  }
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Normal div.top_level_text,
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Disabled div.top_level_text,
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Selected div.top_level_text {
    float:left;
    margin:0 5px;
  }
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Normal i,
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Disabled i,
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Selected i {
    height:inherit;
    line-height:inherit;
    float:left;
    color:inherit;
    margin:0 5px;
    font-size:1.1em;
  }
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Disabled {
    color:#d1d1d1;
    background-color:#ededed;
  }
  div.dhtmlxMenu_dhx_terrace_TopLevel_Item_Selected {
    color:#2e2e2e;
    background-color:#ebebeb;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon {
    position:absolute;
    border:1px solid #ccc;
    *border:1px solid #c7c7c7;
    box-shadow:0 0 5px rgba(127,127,127,0.35);
    padding:3px 0;
    border-bottom-left-radius:1px;
    border-bottom-right-radius:1px;
    border-top-right-radius:1px;
    background-color:#f5f5f5;
    overflow:hidden;
    cursor:default;
    line-height:normal;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td {
    font-family:Arial,Helvetica;
    font-size:13px;
    color:#333;
    line-height:normal;
    padding:0 5px;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected td {
    background-color:#fff3a1;
    color:black;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon {
    width:18px;
    text-align:center;
    vertical-align:middle;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon img.sub_icon {
    float:left;
    margin:0;
    width:18px;
    height:18px;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_icon i {
    float:left;
    text-align:center;
    width:18px;
    height:18px;
    line-height:17px;
    font-size:1.1em;
    color:inherit;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_item_text {
    padding:0 16px 0 1px;
    height:26px;
    line-height:25px;
    white-space:nowrap;
    text-align:left;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_item_text {
    color:#bbb;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis td.sub_item_icon i {
    color:#bbb!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_hk {
    padding-left:8px;
    padding-right:8px;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl td.sub_item_hk div.sub_item_hk {
    font-family:Arial,Helvetica;
    font-size:13px;
    color:#737373;
    text-align:right;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis td.sub_item_hk div.sub_item_hk {
    color:#c8c8c8!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.complex_arrow {
    width:4px;
    margin:0 2px 0 5px;
    height:22px;
    background-image:url("https://cdn.dhtmlx.com/edge/skins/terrace/imgs/dhxmenu_terrace/dhxmenu_subar.gif");
    background-repeat:no-repeat;
    background-position:0 0;
    overflow:hidden;
    font-size:1px;
    float:right;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_selected div.complex_arrow {
    background-position:-4px 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.complex_arrow {
    background-position:-8px 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.complex_arrow_loading {
    width:11px;
    height:22px;
    background-position:center center;
    background-repeat:no-repeat;
    background-image:url("https://cdn.dhtmlx.com/edge/skins/terrace/imgs/dhxmenu_terrace/dhxmenu_loader.gif");
    float:right;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon {
    float:left;
    margin:0;
    width:18px;
    height:18px;
    background-position:top right;
    background-repeat:no-repeat;
    background-image:url("https://cdn.dhtmlx.com/edge/skins/terrace/imgs/dhxmenu_terrace/dhxmenu_chrd.gif");
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.chbx_0 {
    background-position:0 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.chbx_1 {
    background-position:-18px 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.chbx_0 {
    background-position:-36px 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.chbx_1 {
    background-position:-54px 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.rdbt_0 {
    background-position:-72px 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl div.sub_icon.rdbt_1 {
    background-position:-90px 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.rdbt_0 {
    background-position:-108px 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon table.dhtmlxMebu_SubLevelArea_Tbl tr.sub_item_dis div.sub_icon.rdbt_1 {
    background-position:-126px 0!important;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon tr.sub_sep td {
    padding:2px 0;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon div.sub_sep {
    position:static;
    font-size:1px;
    line-height:1px;
    height:1px;
    width:100%;
    border-top:1px solid #e8e8e8;
  }
  iframe.dhtmlxMenu_IE6CoverFix_dhx_terrace {
    position:absolute;
    border:none;
    background:#000;
    filter:progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_ArrowUp,
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_ArrowUp_Over,
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_ArrowUp_Disabled {
    position:relative;
    font-size:1px;
    border-bottom:1px solid #ccc;
    background-image:url("https://cdn.dhtmlx.com/edge/skins/terrace/imgs/dhxmenu_terrace/dhxmenu_arrow_up.gif");
    background-repeat:no-repeat;
    background-position:center center;
    padding:8px 0;
    margin-bottom:3px;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_ArrowUp_Disabled {
    background-image:url("https://cdn.dhtmlx.com/edge/skins/terrace/imgs/dhxmenu_terrace/dhxmenu_arrow_up_dis.gif");
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_ArrowDown,
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_ArrowDown_Over,
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_ArrowDown_Disabled {
    position:relative;
    font-size:1px;
    border-top:1px solid #ccc;
    background-image:url("https://cdn.dhtmlx.com/edge/skins/terrace/imgs/dhxmenu_terrace/dhxmenu_arrow_down.gif");
    background-repeat:no-repeat;
    background-position:center center;
    padding:8px 0;
    margin-top:3px;
  }
  div.dhtmlxMenu_dhx_terrace_SubLevelArea_ArrowDown_Disabled {
    background-image:url("https://cdn.dhtmlx.com/edge/skins/terrace/imgs/dhxmenu_terrace/dhxmenu_arrow_down_dis.gif");
  }
  